import React, {useState, useEffect, useRef} from 'react'

import {useFormik} from 'formik';
import * as Yup from 'yup';

import FPCmiscbar2 from './FPCmiscbar2.js'
import FPCfooter from './FPCfooter.js'
import CurrencyFormat from 'react-currency-format';

import {Button, Modal, FormText} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import Navtop from './Navtop.js'
import { Link } from "react-router-dom";

import InputGroup from 'react-bootstrap/InputGroup'

import FormControl from 'react-bootstrap/FormControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGavel, faComments } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Countdown from 'react-countdown';

import noimgcar from '../assets/img/img-placeholder.jpg';
import noimgbike from '../assets/img/img-placeholder-bike.jpg';
import noimghouse from '../assets/img/img-placeholder-house.jpg';
import noimgmisc from '../assets/img/img-placeholder-misc.jpg';
import noimggeneric from '../assets/img/img-placeholder-generic.jpg';
import noimgequip from '../assets/img/img-placeholder-equip.jpg';
import noimgatv from '../assets/img/img-placeholder-atv.jpg';

import flagthai from '../assets/img/flagThai.png';
import flagphil from '../assets/img/flagPhil.png';

import Slider from "react-slick";
import Spinner from 'react-bootstrap/Spinner'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import NavDummyCrumbs from './NavDummyCrumbs';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PopoverContent from 'react-bootstrap/PopoverContent'
import PopoverTitle from 'react-bootstrap/PopoverTitle'
import Popover from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'
//import { baseUrl } from "./config";

import ItemInquiryForm from './ModalItemInquiry.js'
import ItemInquiryTy from './ModalItemInquiryTy.js'

import ItemPackageDets from './ItemPackageDets.js';
import "../../node_modules/draft-js/dist/Draft.css";



function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

var showconf = true;
var showbnc = true;
var thebamnt = 0;
var currbid = 0;
var askprc = 0;
var oktobid = true;
var biddesc = 'STARTING';
var bbanotmin = true;


function placebid(eqpn) {

    var frm = document.forms["bidform"];
    var thisOffer = frm.bidamount.value;
    
    thebamnt = frm.bidamount.value;
    
    //PRELIMINARY CHECKS ON BIDFORM
    if (thisOffer == ""){alert("Please Enter An Offer Amount");showconf=false;return showconf;}
        
    var Chars = "0123456789";
    var strbid = new String() ;
    var re = new RegExp (',', 'gi') ;
    for (var i = 0; i < frm.bidamount.value.length; i++) { 
        if (Chars.indexOf(frm.bidamount.value.charAt(i)) == -1) {alert("Invalid Character(s)\n\nOnly numbers (0-9) are allowed.");showconf=false;return showconf;}
    }
    strbid = frm.bidamount.value;
    // Replace comma with nothing.
    
    var newstrbid = strbid.replace(re, '') ;
    //alert("bid:"+ Number(newstrbid)  + "| mina:" + Number(ps_minabid));
    //if ( Number(newstrbid) < Number(hiofr) ) {alert('Your Bid Amount must be GREATER THAN or EQUAL TO the minimun acceptable bid.');showconf=false;return showconf;}
    
    

    setTimeout(function(){
        
        dspnewdata();

        showconf=true;return showconf;
    
    },500);

        

}
function goBuyNow(eqpn) {

    
    

    setTimeout(function(){
        
         showbnc=true;return showbnc;
    
    },500);

        

}

function getLatestUIBStat(en,ola){
    // var params = "uid=<cfoutput><cfif parameterexists(cookie.tuid)>#cookie.tuid#<cfelse>0</cfif></cfoutput>&enum="+en+"&aucid="+ola;
    // http( "POST" , "/v6/com/olaModLib.cfc?method=latestUIBStat" , getLatestUIBStat_cb, params );

    var islogged = readCookie('islogged');
    var cuid = 0; if (islogged>0){cuid=islogged}

    fetch(`https://clubcamayan.com/rest/proja/api/latestUIBStat?uid=${cuid}&enum=${en}&aucid=olaia`)
    .then(res => res.json())
    .then(
      (result) => {

        var tfvcont = document.forms["luibsCont"];
        tfvcont.uisbidder.value = result[0].UISBIDDER;
        tfvcont.ubidlim.value = result[0].UBIDLIM;
        tfvcont.ibidstart.value = result[0].IBIDSTART;
        tfvcont.ibidi.value = result[0].IBIDI;
        tfvcont.inumbids.value = result[0].INUMBIDS;
        tfvcont.icurrbid.value = result[0].ICURRBID;
        tfvcont.ihibidder.value = result[0].IHIBIDDER;
        tfvcont.icurrmaxbid.value = result[0].ICURRMAXBID;
        tfvcont.imaxbidder.value = result[0].IMAXBIDDER;
        tfvcont.ireserve.value = result[0].IRESERVE;
        tfvcont.ibuynow.value = result[0].IBUYNOW;
        tfvcont.iendcd.value = result[0].IENDCD;
        tfvcont.ienddsp.value = result[0].IENDDSP;

        // console.log('tfvcont.icurrbid.value', tfvcont.icurrbid.value)
        

        //determine new minimum bid allowed
		var mina = 0;
		if (result[0].ICURRBID == ""){	var bam = Number(result[0].IBIDSTART);}else{var bam = Number(result[0].ICURRBID);}
		//if the user has active maxbid on the item
		if (Number(tfvcont.uid.value) == Number(result[0].IMAXBIDDER)) {
			mina = Number(result[0].ICURRMAXBID) + Number(result[0].IBIDI);
		}else{
			//if user is the current highest bidder on the item
			if (Number(tfvcont.uid.value) == Number(result[0].HIBIDDER)) {
				mina = bam	+ (Number(result[0].IBIDI) * 2);
			}else{
				mina = bam	+ Number(result[0].IBIDI);
			}
		}
        tfvcont.bmina.value = mina;
        
        var pbvarcont = document.forms["pbvarcont"];
		pbvarcont.ubidlim_ps.value = result[0].UBIDLIM;
		pbvarcont.minabid_ps.value =  mina;
		//alert("result[0].icurrbid:"+result[0].icurrbid);
		if (result[0].ICURRBID == ""){
			pbvarcont.currbid_ps.value =  result[0].IBIDSTART;
		}else{
			pbvarcont.currbid_ps.value =  result[0].ICURRBID;
		}
		
		pbvarcont.bidinc_ps.value =  result[0].BIDI;
		pbvarcont.currmaxbid_ps.value =  result[0].ICURRMAXBID;
        
      },
      (error) => {}
    )

}

function dspnewdata(){
    var tfvc = document.forms["luibsCont"];
    
    //CURRENT BID AND INCREMENT
    //
    // console.log('tfvc.icurrbid.value',tfvc.icurrbid.value);
    //if (tfvc.icurrbid.value == ""){
    //    document.getElementById("dsp_currbid").innerHTML = String.fromCharCode(8364)+" "+formatCurrency(tfvc.ibidstart.value);
    //}else{
    //    document.getElementById("currbidcont").innerHTML = "Current Bid";
    //    document.getElementById("dsp_currbid").innerHTML = String.fromCharCode(8364)+" "+formatCurrency(tfvc.icurrbid.value);
        
    //}
    //document.getElementById("dsp_bidi").innerHTML = String.fromCharCode(8364)+" "+formatCurrency(tfvc.ibidi.value);
    
    //END TIME COUNTDOWN AND DISPLAY TEXT
    //document.getElementById("countdown1").innerHTML = tfvc.iendcd.value;
    //document.getElementById("dsp_edt").innerHTML = tfvc.ienddsp.value;
    
    
}
//===============================================================================================

  









function formatCurrency(num) {
    var num = num.toString().replace(/\$|\,/g,'');
    if(isNaN(num))
        num = "0";
    var sign = (num == (num = Math.abs(num)));
    num = Math.floor(num*100+0.50000000001);
    var cents = num%100;
    num = Math.floor(num/100).toString();
    if(cents<10)
        cents = "0" + cents;
    for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++)
    num = num.substring(0,num.length-(4*i+3))+','+
    num.substring(num.length-(4*i+3));
    return (((sign)?'':'-') + '' + num + '.' + cents);
}

//=====================================================================================================================================================================================================
function Categidets ({match}) {

    

    const [eqpd, setEqpd] = useState([]);
    const [ofrStat, setOfrStat] = useState([]);
    const [imgarr, setImgarr] = useState([]);
    const [ofimgnams,setofimgnams] = useState([]);
    const [errmess, setErrmess] = useState([]);
    const [tcno, setTcno] = useState(0);
    const [teno, setTeno] = useState(0);

    const [numofrs, setnumofrs] = useState(0);
    const [hiofr, sethiofr] = useState(0);
    const [hiofrwho, sethiofrwho] = useState(0);
    
    
    const [currbid_unstable, setcurrbid] = useState(0);
    const [bidi, setbidi] = useState(0);

    const [dontexec, setdontexec] = useState(0);

    const [modalShow, setModalShow] = useState(false);
    const [modalShowty, setModalShowty] = React.useState(false);
    

    const [show, setShow] = useState(false);
    const [showbnconf, setbnShow] = useState(false);
    
    const [strtdat, setstrtdat] = useState('');
  
    const handleClose = () => setShow(false);
    const handleCloseBNCnoreload = () => setShow(false);
    
    const handleCloseReload = (eqpn) => {
        setShow(false);
        window.location = `/makeanoffer/${eqpn}`;
    }
    const handleCloseBNC = (eqpn) => {
        setbnShow(false);
        window.location = `/makeanoffer/${eqpn}`;
    }
    const handleShow = () => setShow(true);
    const handlebnconfShow = () => setbnShow(true);
    
    const cunam = readCookie('uname'); 
    const cbidno = readCookie('bidno');
    const capproved = readCookie('approved');
    const cuserid = readCookie('userid');
    const cemail = readCookie('email');
    const cbidlimit = readCookie('bidlimit');
    const islogged = readCookie('islogged');
    const [cmina, setcmina] = useState('');

    const [showbidamntmsg, setbidamntmsg] = useState(false);
    const [showbbmsg, setbbmsg] = useState(false);
    const [withthisamnt, setwiththisamnt] = useState('');
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const refbb = useRef(null);

    
    const handleMOver = (event) => {
        setbidamntmsg(true);
        setTarget(event.target);
    };
    const handleMOut = (event) => {
        
        setbidamntmsg(false);
        setTarget(event.target);
    };
    const handleBBMOver = (event) => {
        var frm = document.forms["bidform"];
        var thisBid = frm.bidamount.value;
        thebamnt = frm.bidamount.value;
        setwiththisamnt(thebamnt);
        setbbmsg(true);
        setTarget(event.target);
    };
    const handleBBMOut = (event) => {
        setbbmsg(false);
        setTarget(event.target);
    };


  
    useEffect(() => {
        fetch(`https://clubcamayan.com/rest/proja/api/getidets?olaid=DAX1&equipnum=${match.params.equipnum}`)
        .then(res => res.json())
        .then(
          (result) => {
            setEqpd(result);
            
            setcurrbid(result[0].bidstart)
            //setbidi(result[0].bidincrmnt)
            setstrtdat(result[0].startdate)

         

            for (var {consignorno: cno, equipno: eno, bidstart: bidstart} of result) {
                setTcno(cno);
                setTeno(eno);
            }

            let imgarr_ = [];
            for (let i = 0; i < 20; i++) {
                const key = "image" + i;
                const value = result[0][key];
                if (value !== "") {
                    imgarr_.push(value);
                }
            }
            setImgarr(imgarr_);

            //console.log('imgnames:', result[0].imgnames);

            var ofimgnams_tmp=result[0].imgnames.replace(/'/g, "").split(",");
            
            setofimgnams(ofimgnams_tmp);
            sessionStorage.setItem('aucsite', result[0].aucsiteid);

          },
          (error) => {setErrmess(error);console.log(error)}


        )
   }, [dontexec])

   useEffect(() => {
    fetch(`https://clubcamayan.com/rest/proja/api/offerstat?olaid=DAX1&equipnum=${match.params.equipnum}`)
    .then(res => res.json())
    .then(
      (result) => {

        
        setOfrStat(result);

        setnumofrs(result[0].NUMOFFERS)
        sethiofr(result[0].HIOFFER)
        sethiofrwho(result[0].HIOFFERWHO)
        
        // console.log('askprc:', askprc)
        // console.log('currbid:', currbid)
        // console.log('result[0].INCRMNT:', result[0].INCRMNT)

        
      },
      (error) => {setErrmess(error);console.log(error)}


    )
    window.scrollTo(0, 0)
    }, [match.params.equipnum])

  
    

   
    const getplaceholder = ({ catid }) => {

        switch (catid) {
        case 'ATV': return noimgatv;
        case 'AUT': return noimgcar;
        case 'PUT': return noimgcar;
        case 'PRP': return noimghouse;
        case 'MIS': return noimgmisc;
        case 'MTC': return noimgbike;
        case 'HEX': return noimgequip;
        default: return noimggeneric;
        }
        
    };

    const settings = {
        customPaging: function(i) {
            return (
            // <a><img src={`https://bidlive.bid/photos/olaitems/olaia-${tcno}-${teno}_${i + 1}.jpg?${new Date().getTime()}`} /></a>
            <a><img src={`https://bidlive.bid/photos/olaitems/${ofimgnams[i]}?${new Date().getTime()}`} style={{padding: "1px"}} /></a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    };

    const baseURL = 'https://bidlive.bid/photos/olaitems'
    

    let ngaun = new Date;
    let istrtdat = new Date(strtdat);
    if (cbidno>0 && capproved>0){
        if (ngaun < istrtdat){
            oktobid = false;
            // console.log('not good to bid');
        }else{
            oktobid = true;
            // console.log('good to bid');
        }
    }else{
        oktobid=false;
    }

    
    
    
    
    
    
    //getLatestUIBStat(match.params.equipnum,'olaia')

    //console.log("currentbid:", currbid);
    //console.log("bidi:", bidi)

    const minAllowed = currbid + bidi;
    
    var now = new Date();
    now.setTime(now.getTime() + 2 * 3600 * 1000);
    document.cookie = "cminallowed="+currbid+"; expires=" + now.toUTCString() + "; path=/";
    
    const cdrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            
            // window.location.href = "http://ww2.bidlive.bid";
            window.location = `/`;
        }
        // Render a countdown
        return (
          <span>
            {days}d {hours}h {minutes}m {seconds}s
          </span>
        );
      };
                    





      // var pbvarcont = document.forms["pbvarcont"];
    
    // console.log('pbvarcont.minabid_ps.value',pbvarcont.minabid_ps.value);

    // useEffect(()=>{
    //     var pbvarcont = document.forms["pbvarcont"];
	//     pbvarcont.nxtminbid.value =  minAllowed;
    // })
    
        
        
    //setfordefval(fordefval=>minAllowed);

// ====================================================================================================================================================
//  PLACE THE ACTUAL BID ==============================================================================================================================
// ====================================================================================================================================================
    function handleSubmitBidbn(eqpn,tuid) {




    }
    function handleSubmitBid(eqpn,tuid) {

        console.log('asdfasdfasdfasdfasdfasdfasdf')

        var frm = document.forms["bidform"];

		var thisbid = frm.bidamount.value;
		var thisequip = eqpn;
        var thisuser = tuid;
        // alert(eqpn);
        // alert(tuid);
		
		document.getElementById("confbmsg").style.display = "none";
        document.getElementById("confbutton").style.display = "none";
		document.getElementById("sendingbmsg").style.display = "block";
        
				
		// FIRST CHECK THE LATEST USER AND ITEM BID STATISTICS
		
		setTimeout(function(){
			
			//DISPLAY NEW OR OLD DATA ON DIVS OR SPANS
		
			
			//NEW BID DETECTED
			//alert(ps_currbid); alert(tfvc.icurrbid.value);
		
            
			//IF USER IS STILL A BIDDER AND NO BID DETECTED SINCE ITEM PAGE LOAD
			//if (tfvc.uisbidder.value == "1"){
			
				//document.getElementById("warnbox").style.display = "none";
		
				
				//PLACE THE ACTUAL BID
				placeOfferNow(thisuser,thisequip,thisbid);
				
			//}else{
			//	alert("no more a bidder");
				//TODO: Redirect
			//}
			
		
		},1000);

        //close the modal
       
    };
    
    function placeOfferNow(uid,eqpn,bidamnt){
		var params = "uid="+uid+"&enum="+eqpn+"&bidamnt="+bidamnt;
        //http( "POST" , "/v6/com/olaModLib.cfc?method=placeBidNow" , placeBidNow_cb, params );
        fetch(`https://clubcamayan.com/rest/proja/api/placeOfferNow?${params}`)
        .then(res => res.json())
        .then(
        (result) => {
            // console.log(result);  
            var ppbvc = document.forms["postBidVarCont"];
            

            

            document.getElementById("sendingbmsg").style.display = "none";
            document.getElementById("sendingbmsg").innerHTML = "Done";
            document.getElementById("pbalertmsgbot").innerHTML = result;
            
            console.log(result);

            // setTimeout(function(){
			// 	window.location = `/forsale/${eqpn}`;
			// },1000);

            //setTimeout(function(){
            //    window.location = `/forsale/${eqpn}`;
            //},500);

		    //ppbvc.placebidmess.value = obj.pbm;
        
        },
        (error) => {}

        )

    }
    

    return(


    <Container fluid>
        
    <Navtop /> 



      <Row style={{borderTop: '1px solid #cccccc'}}><Col sm={12} md={12} style={{maxWidth: '1140px', color: 'black', borderTop: '0px solid #cccccc'}}> 
        
      </Col></Row> 
      <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black'}}>

      <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
            
            {console.log(eqpd)}

          {eqpd.map(idet => (

        
          <Container key={idet.equipnum}>
                
                <Row><Col xs={12} sm={12} md={12}>
                        <NavDummyCrumbs /> 
                </Col></Row>


              <Row>
                <Col sm={12} md={12}>
                    {/* {match.params.equipnum}    {errmess} */} &nbsp;
                </Col>

              </Row>

              <Row>
                <Col sm={12} md={6} lg={6} className="d-flex justify-content-center" style={{position: 'relative'}}>

                    <Container>
                    <Row>
                    <Col>
                        
                        <div style={{position: 'relative'}}>

                    

                    {
                        ofimgnams[0] !== '' 
                            // IF THERE IS AN IMAGE
                            ? 
                            (
                                ofimgnams.length > 1
                                //IF MORE THAN ONE IMAGE
                                ? (
                                <div className="container" style={{width: '500px', paddingLeft: '0px', paddingRight: '0px', position: 'relative'}}>
                                    <Slider {...settings}>
                                        {ofimgnams.map(timg => (
                                            <div key={timg} style={{position: 'relative'}}>
                                                <img src={`https://bidlive.bid/photos/olaitems/${timg}?${new Date().getTime()}`} />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                )
                                // ONLY ONE IMAGE                
                                : (
                                    <img src={`https://bidlive.bid/photos/olaitems/${ofimgnams[0]}?${new Date().getTime()}`} />    
                                )
                            )

                            
                                // <div className="container" style={{width: '500px', paddingLeft: '0px', paddingRight: '0px'}}>
                                // {/* <img src={`https://bidlive.bid/photos/olaitems/${idet.image0}`} /> */}
                                //     <Slider {...settings}>
                                //         {imgarr.map(timg => (

                                //             <div>
                                //                 <img src={`https://bidlive.bid/photos/olaitems/${timg}`} />
                                //             </div>
                                //         ))}
                                //     </Slider>
                                // </div>


                            // IF THERE IS NO IMAGE
                            : <div style={{border: '1px solid #cccccc', width: 400, height: 300, paddingLeft: '40px', paddingTop: '40px'}}>
                                {(() => {
                                    switch (idet.catid.trim()) {
                                        case 'ATV': return (<img src={noimgatv} />);
                                        case 'AUT': return (<img src={noimgcar} />);
                                        case 'PUT': return (<img src={noimgcar} />);
                                        case 'PRP': return (<img src={noimghouse} />);
                                        case 'MIS': return (<img src={noimgmisc} />);
                                        case 'MTC': return (<img src={noimgbike} />);
                                        case 'HEX': return (<img src={noimgequip} />);
                                        default: return (<img src={noimggeneric} />)
                                    }
                                })()}
                            </div>
                    }
                    </div>
                        
                    </Col>
                    </Row>    

                    <Row>
                    <Col>
                        

                        {/* ------------------------------------------------------------------------------------------------------------------------------------ */}


                        <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '30px'}}>

                        <Row>
                            <Col xs={12} sm={12} md={12} style={{fontSize: '14px',marginBottom: "15px", paddingTop: "6px", paddingBottom: "6px", fontWeight: "bold", backgroundColor: "#dedede"}}>PROMO DETAILS</Col>
                            
                        </Row>
                        
                        {idet.mpkgdets?(
                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    
                                    <ItemPackageDets mpkgdets={idet.mpkgdets} />
                                </Col>

                            </Row>
                        ):null}

                        {idet.mileage!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>MILEAGE</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.mileage} {idet.mileageunit}</Col>
                        </Row>
                        ):null}
                        {idet.hours!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>HOURS</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.hours}</Col>
                        </Row>
                        ):null}
                        {idet.sno!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>VIN/SERIAL NO.</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.sno}</Col>
                        </Row>
                        ):null}
                        {idet.enginetype!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>ENGINE TYPE</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.enginetype}</Col>
                        </Row>
                        ):null}
                        {idet.horsepower!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>HORSE POWER</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.horsepower}</Col>
                        </Row>
                        ):null}
                        {(idet.year!==""||idet.year!=0)?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>YEAR</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.year}</Col>
                        </Row>
                        ):null}
                        {idet.grntlight!=="na"?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>LIGHTS</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                <span style={{backgroundColor: `${idet.grntlight}`, color: '#cccccc', borderRadius: '3px', padding: '5px'}}>{idet.grntlight}</span>
                            </Col>
                        </Row>
                        ):null}
                        {/* idet.autkeys!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>KEYS</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                {idet.autkeys}
                            </Col>
                        </Row>
                        ):null */}
                        {idet.bodystyle!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>BODY STYLE</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                {idet.bodystyle}
                            </Col>
                        </Row>
                        ):null}
                        {idet.trim!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>TRIM</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                {idet.trim}
                            </Col>
                        </Row>
                        ):null}
                        {idet.colorint!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>INTERIOR COLOR</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                {idet.colorint}
                            </Col>
                        </Row>
                        ):null}
                        {idet.colorext!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>EXTERIOR COLOR</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                {idet.colorext}
                            </Col>
                        </Row>
                        ):null}
                        {idet.odostat!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>ODOMETER STATUS</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                {idet.odostat}
                            </Col>
                        </Row>
                        ):null}
                        {idet.drive!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>DRIVE</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                {idet.drive}
                            </Col>
                        </Row>
                        ):null}
                        {idet.transtype!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>TRANSMISSION</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                {idet.transtype}
                            </Col>
                        </Row>
                        ):null}
                        {idet.cartitle!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>TITLE</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                {idet.cartitle}
                            </Col>
                        </Row>
                        ):null}
                        {idet.caropt!=="these"?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>CAR OPTIONS:</Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                
                                {(() => { 
                                    var arr = idet.caropt.split(",");
                                    var arr_fer = arr.shift();
                                    return arr.toString();
                                })()}

                            </Col>
                        </Row>
                        ):null}
                        
                    </Container>

  
                    </Col>
                    </Row>

                    {/* ------------------------------------------------------------------------------------------------------------------------------------ */}


                    </Container>

                </Col>
                <Col sm={12} md={6} style={{color: 'black', marginLeft: '0px', paddingLeft: '0px'}}>

                
                    <div style={{fontSize: '18px', fontWeight: 'bold'}}>
                        {idet.shortdesc}
                    </div>
                    <div style={{fontSize: '14px', color: '#777777', fontWeight: 'bold'}}>{idet.addinfo} {idet.addinfo2?`(${idet.addinfo2})`:''}</div>
                    
                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '10px'}}>
                        <Row>
                            <Col xs={6} sm={6} md={3} style={{fontSize: '12px'}}>PROMO CODE</Col>
                            <Col xs={6} sm={6}  md={9} style={{fontSize: '18px', fontWeight: 'bold'}}>{idet.aucsiteid=='AP1'?idet.catalognostr:idet.equipnum}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} sm={6} md={3} style={{fontSize: '12px'}}>LOCATION</Col>
                            <Col xs={6} sm={6}  md={9} style={{fontSize: '14px', fontWeight: 'normal'}}>
                                {idet.locmap!==""?<>
                                    <a href={idet.locmap} target="_blank">{idet.itemloc}</a>&nbsp;&nbsp;
                                </>:<>
                                    {idet.itemloc}
                                </>}

                                
                                {/* 
                                {idet.countryid=="US"?<>
                                    <a href="https://goo.gl/maps/XuTfUG92EVRCxMD27" target="_blank">{idet.itemloc}</a>&nbsp;&nbsp;
                                </>:<>
                                    <a href="https://maps.app.goo.gl/NUhMmQkP4yREznhz6" target="_blank">{idet.itemloc}</a>&nbsp;&nbsp;
                                    
                                </>}
                                */}
                                
                                {/* {idet.countryid==='TH'?
                                    <img src={flagthai}/>
                                :
                                    <img src={flagphil}/>
                                } */}
                            </Col>
                        </Row>
                        {idet.aucsiteid=='TH1'?<>
                        <Row>
                            <Col xs={6} sm={6} md={3} style={{fontSize: '12px'}}>QUANTITY</Col>
                            <Col xs={6} sm={6}  md={9} style={{fontSize: '14px', fontWeight: 'normal'}}>
                              {idet.qty}
                            </Col>
                        </Row>
                        </>:null}



                    </Container>
                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '15px'}}>
                        <Row>
                            <Col md={12}>

                            Ends - On Seller's Acceptance.
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Row style={{paddingTop: "0px"}}>
                                <Col xs={6} sm={6} md={7} style={{fontSize: '14px'}}>HIGHEST OFFER:</Col>
                                <Col xs={6} sm={6}  md={5} style={{fontSize: '16px', fontWeight: 'normal'}}>

                                {hiofr>0?<>
                                    <span id='dsp_currbid' style={{fontWeight: "bold"}}>
                                        <CurrencyFormat id='currbidcont_frmtd' value={hiofr} displayType={'text'} thousandSeparator={true} prefix={String.fromCharCode(idet.auctionid=="DAX1"?36:8364)} />
                                    </span>    
                                        &nbsp;
                                        <span id="hbmess" style={{color: 'green', fontSize: '12px'}}>{Number(islogged)===hiofrwho?<></>:null}</span>
                                </>:<>
                                    <span id='dsp_currbid' style={{fontSize: "12px"}}>none</span>
                                </>}
                                    
                                    
                                </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                   <span style={{fontWeight: "bold"}}>{numofrs} Offers</span> 

                            </Col>
                        </Row>

                    </Container>
                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '10px'}}>
                        <Row>
                            
                            <Col sm={12} md={6}>
                            
                            {/* {console.log("minallowed",minAllowed,'[',currbid_unstable,'+',bidi,']')} */}

                            <Form id='bidform' ref={ref}>

                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text style={{backgroundColor: 'white', borderRightWidth: '0px'}}>{String.fromCharCode(idet.auctionid=="DAX1"?36:8364)}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        id="bidamount"
                                        type="text"
                                        defaultValue={withthisamnt}
                                        aria-label="bidamnt"
                                        aria-describedby="bidamnt"
                                        style={{ borderLeftWidth: '0px'}}
                                        
                                        onMouseOver={handleMOver}
                                        onMouseOut={handleMOut}
                                        //onFocus={handleMOut}
                                        
                                    />
                                </InputGroup>
                                <Overlay
                                    show={showbidamntmsg}
                                    target={target}
                                    placement="top"
                                    container={ref.current}
                                    containerPadding={20}
                                >
                                    <Popover id="popover-contained">
                                    <Popover.Title as="h3">&nbsp;&nbsp;About your Offer...</Popover.Title>
                                    <Popover.Content style={{backgroundColor: '#FDFFB6'}}>
                                        Place your offer and it will be sent to the owner, for consideration. The item will be sold when the owner accepts a good offer.<br/>
                                    </Popover.Content>
                                    </Popover>
                                </Overlay>

                            </Form>

                            </Col>
                            <Col sm={6} md={6}>
                                    <>
                                        {/* <Button variant="primary" onClick={handleShow}>
                                        Launch demo modal
                                        </Button> */}

                                        {/* ====================================================================================================================================================
                                        PLACE BID CONFIRMATION MODAL
                                        ==================================================================================================================================================== */}
                                        <Modal show={show} onHide={handleClose} animation={false} centered>
                                            <Modal.Header closeButton style={{backgroundColor: "#FDFFB6"}}>
                                                <Modal.Title>
                                                    <span id='confbmsg' style={{display: 'block'}}>Confirm your offer</span>
                                                    <span id='sendingbmsg' style={{display: 'none'}}><Spinner animation="border" />&nbsp;&nbsp;Submitting your offer...</span>
                                                    <span id='pbalertmsgtop' style={{display: 'none'}}><br /></span>

                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <span id="mainpbc">
                                                {idet.shortdesc}<br/>
                                                <span style={{color: 'black', fontSize: '18px', fontWeight: 'bold'}}>Your Offer: {thebamnt}</span>
                                                </span><br />
                                                <span id="pbalertmsgbot" style={{color: 'black',fontSize: '14px', fontWeight: 'bold'}}></span>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={()=>handleCloseReload(idet.equipnum)}>
                                                    Close
                                                </Button>
                                                <Button id="confbutton" variant="primary" onClick={()=>handleSubmitBid(idet.equipnum,islogged)} style={{display: 'block'}}>
                                                    Confirm
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </>

                                
                                <div>

                                    

                                    
                                    {oktobid?(
                                        <span ref={refbb}>
                                        <Button variant="primary" onClick={() =>{placebid(idet.equipnum); if(showconf==true){handleShow()}}}>
                                            MAKE OFFER
                                        </Button>
                                       
                                        </span>
                                    ):(
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
                                            {capproved>0?'':'You are not signed in or not yet approved.'}
                                        </Tooltip>}>
                                        <span className="d-inline-block">
                                            <Button variant="primary" style={{ pointerEvents: 'none' }}> MAKE OFFER </Button>      
                                        </span>
                                        </OverlayTrigger>
                                        
                                    )}

                                    


                                </div>
                            </Col>





                        </Row>
                        
                    </Container>
                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '0px'}}>
                        <Row>
                            
                            <Col sm={12} md={6}>
                            
                            <Row>
                                <Col xs={6} sm={6} md={7} style={{fontSize: '14px'}}>BUY NOW PRICE:</Col>
                                <Col xs={6} sm={6}  md={5} style={{fontSize: '16px', fontWeight: 'bold'}}>
                                
                                <CurrencyFormat id='currbidcont_frmtd' value={idet.aucsiteid!=="TH1"?idet.buynowprice:idet.buynowprice} displayType={'text'} thousandSeparator={true} prefix={String.fromCharCode(idet.auctionid=="DAX1"?36:8364)} />
                                </Col>
                            </Row>



                            </Col>
                            <Col sm={6} md={6}>
                                    <>
                                        {/* <Button variant="primary" onClick={handleShow}>
                                        Launch demo modal
                                        </Button> */}

                                        {/* ====================================================================================================================================================
                                        BUY NOW CONFIRMATION MODAL
                                        ==================================================================================================================================================== */}
                                        <Modal show={showbnconf} onHide={handleCloseBNCnoreload} animation={false} centered>
                                            <Modal.Header closeButton style={{backgroundColor: "#FDFFB6"}}>
                                                <Modal.Title>
                                                    <span id='confbmsg-bn' style={{display: 'block'}}>Confirm Buy Now</span>
                                                    <span id='sendingbmsg-bn' style={{display: 'none'}}><Spinner animation="border" />&nbsp;&nbsp;Submitting...</span>
                                                    <span id='pbalertmsgtop-bn' style={{display: 'none'}}><br /></span>

                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <span id="mainpbc">
                                                {idet.shortdesc}<br/>
                                                <span style={{color: 'black', fontSize: '18px', fontWeight: 'bold'}}>Buying now at: {idet.shootPrice}</span>
                                                </span><br />
                                                <span id="pbalertmsgbot-bn" style={{color: 'black',fontSize: '14px', fontWeight: 'bold'}}></span>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={()=>handleCloseBNC(idet.equipnum)}>
                                                    Close
                                                </Button>
                                                <Button id="confbutton" variant="primary" onClick={()=>handleSubmitBidbn(idet.equipnum,islogged)} style={{display: 'block'}}>
                                                    Confirm
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </>

                                
                                <div>

                                    

                                    
                                    {oktobid?(
                                        <span ref={refbb}>
                                        <Button variant="primary" 
                                            onClick={() =>{goBuyNow(idet.equipnum); if(showbnc==true){handlebnconfShow()}}}
                                            onMouseOut={handleBBMOut}
                                            onMouseOver={handleBBMOver}
                                            style={{backgroundColor: "red", border: "0px" }}
                                           >
                                            BUY NOW
                                        </Button>
                                        <Overlay
                                            show={showbbmsg}
                                            target={target}
                                            placement="top"
                                            container={ref.current}
                                            containerPadding={20}
                                        >
                                            <Popover id="buynow">
                                            <Popover.Title as="h3">JUST A CLICK AWAY!</Popover.Title>
                                            <Popover.Content style={{backgroundColor: '#FDFFB6'}}>
                                                <strong>Buy it now for {idet.shootPrice}! You will be asked to confirm.</strong>
                                            </Popover.Content>
                                            </Popover>
                                        </Overlay>
                                        </span>
                                    ):(
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
                                            {capproved>0?'':'You are not signed in or not yet approved.'}
                                        </Tooltip>}>
                                        <span className="d-inline-block">
                                            <Button variant="primary" style={{ pointerEvents: 'none', backgroundColor: "red", border: "0px" }}> BUY NOW </Button>      
                                        </span>
                                        </OverlayTrigger>
                                        
                                    )}
                                </div>
                            </Col>





                        </Row>
                    </Container>






                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '10px'}}>
                        <Row>
                            <Col xs={12} sm={12} md={12} style={{fontSize: '14px', color: '#777777', fontWeight: 'bold'}}>

                            </Col>
                        </Row>
                    </Container>

                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '0px'}}>
                        <Row>
                            <Col xs={12} sm={12} md={12} style={{fontSize: '14px', color: '#777777', fontWeight: 'bold'}}>
                                <div style={{width: '100%', borderBottom: '1px solid #dedede', fontSize: '12px', fontWeight: 'bold', marginBottom: '5px'}}>&nbsp;</div>
                                {/* {idet.addsterms!==""?<div style={{backgroundColor: '#fdffb6', padding: '5px'}}>{idet.addsterms}</div>:''} */}
                                
                            </Col>
                        </Row>
                    </Container>
                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '5px'}}>
                        {idet.dtipermnum!==""?(
                        <Row>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>DTI Permit Number: <span style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.dtipermnum}</span></Col>
                            <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}></Col>
                        </Row>
                        ):null}
                        {/*
                        {idet.reservedprice==0?(
                        <Row>
                            <Col xs={6} sm={6} md={4} style={{fontSize: '12px', paddingBottom: "6px"}}>SALE TYPE</Col>
                            <Col xs={6} sm={6} md={8} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>UNRESERVED</Col>
                        </Row>
                        ):null}
                         
                        idet.buynowprice!==0?(
                        <Row>
                            <Col xs={6} sm={6} md={4} style={{fontSize: '12px', paddingBottom: "6px"}}>BUY NOW PRICE</Col>
                            <Col xs={6} sm={6} md={8} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{String.fromCharCode(8364)+" "+formatCurrency(idet.buynowprice)}</Col>
                        </Row>
                        ):null 
                        
                        {idet.buyerPremium!==0?(
                        <Row>
                            <Col xs={6} sm={6} md={4} style={{fontSize: '12px', paddingBottom: "6px"}}> </Col>
                            <Col xs={6} sm={6} md={8} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}> </Col>
                        </Row>
                        ):null}
                        */}

                       
                       
                        
                    </Container>

                    


                    <br />

                    <Button size='sm' onClick={() => setModalShow(true)}><FontAwesomeIcon icon={faComments} />&nbsp;&nbsp;Inquire About This Item</Button>

                    <ItemInquiryForm
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        onNext={() => setModalShowty(true)}
                        theitem = {idet.equipnum}
                        theitemdesc = {idet.shortdesc}
                        theitemsno = {idet.sno}
                    />
                    <ItemInquiryTy
                        show={modalShowty}
                        onHide={() => setModalShowty(false)}
                    />
                    
                
                    
                
                
                </Col>


              </Row>  
          </Container>

          ))}









          </div>
          <div>
                <br /><br /><br />
             <FPCmiscbar2 />
             <FPCfooter />

            


             
          </div>

    </Col></Row>
    </Container>
    )

}


export default Categidets;


